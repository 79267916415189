.container{
    height:100vh;
    
    >.main{
	display:grid;
	vertical-align:middle;
	height:100%;
	
	>.box{
	    margin:auto;
	    width:100%;
	    
	    >.title{
		font-size:2rem;
		height:4rem;
		line-height:4rem;
		color:#aaa;
		margin:0;
		text-indent:1rem;
		position:relative;

		>span{
		    position:absolute;
		    right:2.6rem;
		    font-size:1.2rem;
		    line-height:4rem;
		    color:#888;
		}
	    }
	    >.video{
		max-height:100vh;
		video{
		    width:100%;
		    max-height:calc(100vh - 8rem);
		    border-top:0.1rem solid #333;
		    border-bottom:0.1rem solid #333;
		}
	    }
	}
    }

    >div:last-child{
	display:none;
    }
}

/*
.container_close>.video{
    height:100vh;
    position:relative;
}
.container_close>.video>span{
    position:absolute;
    height:3rem;
    top:0;
    right:0;
    width:2rem;
    display:block;
    background:#888;
    border-radius:0 0 0 0.3rem;
    text-align:center;
    cursor:pointer;
}
.container_close>.video>span:hover{
    opacity:0.8;
}
.container_close>.video>span>img{
    transform:rotate(90deg);
    margin-top:0.6rem;
}
.container_close>.diskinfo{
    display:none;
}
.container_close>.list{
    display:none;
}
*/
