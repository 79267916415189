.container{
    position:fixed;
    height:3rem;
    top:0;
    right:30rem;
    width:2rem;
    display:block;
    background:#888;
    border-radius:0 0 0 0.3rem;
    text-align:center;
    cursor:pointer;

    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -ms-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;    
}
.container_close{
    position:fixed;
    height:3rem;
    top:0;
    right:0;
    width:2rem;
    display:block;
    background:#888;
    border-radius:0 0 0 0.3rem;
    text-align:center;
    cursor:pointer;

    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -ms-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;    
}
.container:hover{
    opacity:0.8;
}
.container>img{
    transform:rotate(-90deg);
    margin-top:0.6rem;
}
.container_close>img{
    transform:rotate(90deg);
    margin-top:0.6rem;
}
