.titles{
    list-style:none;
    margin:auto;
    font-size:0.8rem;
    text-align:left;
    padding:0;
}
.titles>li{
    border-bottom:0.1rem solid #e3e3e3;
    //background:#fff;
    background:#222;
    color:#fff;
    cursor:pointer;
    height:3rem;
    line-height:3rem;
    position:relative;
}
.titles>li>span.chapter{
    background:#fff;
    display:inline-block;
    height:3rem;
    line-height:3rem;
    width:3rem;
    text-align:center;
    border-right:0.1rem solid #ccc;
    border-bottom:0.1rem solid #ccc;
    margin-right:1rem;
    //background:#f0f0f0;
    background:#222;
}
.titles>li>span.time{
    position:absolute;
    top:0.1rem;
    right:0.4rem;
    height:1.6rem;
    line-height:1.6rem;
    color:yellow;
}
.titles>li>span.size{
    position:absolute;
    top:1.4rem;
    right:0.4rem;
    height:1.4rem;
    line-height:1.4rem;
    color:#ccc;
}
.titles>li:hover{
    opacity:0.8;
}
.titles>li.on{
    background:#800;
}
.titles>li.on>span{
    background:#800;
}

.titles>li.change{
    text-align:center;
    font-size:0.8rem;
}
.titles>li.change:hover{
    background:#555;
}


.title_list{
    text-align:center;
    padding-bottom:5rem;
}
.title_list>button{
    width:14rem;
    margin:auto;
    cursor:pointer;
    margin-top:2rem;
    background:#888;
    height:2.4rem;
    border-radius:0.2rem;
    color:#000;
}
.title_list>button:hover{
    opacity:0.8;
}
