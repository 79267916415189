.container{
    display:grid;
    height:100vh;
    vertical-align:middle;
    background:#fff;
}
.container>div{
    margin:auto;
    text-align:center;
    cursor:pointer;
}
.container>div>img:hover{
    opacity:0.6;
}
.container>div>p{
    font-size:2rem;
    color:#e00;
    margin:auto;
}
