*{
    box-sizing:border-box;
    margin:0;
    padding:0;
}
body{
    background:#000;
    font-size:62.5%;
}

.container{
    display:grid;
    grid-template-columns:1fr 30rem;
    height:100vh;

    >.main{
	grid-column:1/2;
	display:grid;
	
	>.box{
	    margin:auto;
	    width:100%;

	    >.title{
		font-size:2rem;
		height:4rem;
		line-height:4rem;
		color:#aaa;
		margin:0;
		text-indent:1rem;
		position:relative;
		
		>span{
		    position:absolute;
		    right:0.6rem;
		    font-size:1.2rem;
		    line-height:4rem;
		    color:#888;
		}
	    }
	    >.video{
		>video{
		    margin:auto;
		    width:100%;
		    max-width:100%;
		    border-top:0.1rem solid #333;
		    border-bottom:0.1rem solid #333;
		}
	    }
	}
    }
    >.menu{
	grid-column:2/3;
	height:100vh;
	background:#111;

	>div:last-child{
	    height:calc(100vh - 8rem );
	    overflow:auto;
	}
    }
}


.alldisk{
    margin:auto;
    font-size:1rem;
}
.alldisk tr{
    cursor:pointer;
}
.alldisk tr.ng{
    cursor:default;
}
.alldisk tr.ng td{
    background:#ccc;
}
.alldisk tr:hover{
    opacity:0.6;
}
.alldisk td{
    border-collapse: collapse;
    background:#fff;
    border:0.1rem solid #aaa;
    padding:0.3rem;
}
.alldisk td:first-child{
    padding:0.3rem 0;
    font-size:0.7rem;
}
.alldisk td:nth-child(2){
    white-space: nowrap;
    text-align:center;
    font-size:0.7rem;    
}
.alldisk td>span{
    width:100%;
    display:block;
    text-align:center;
    padding:0.2rem;
    color:#fff;
}
