.container{
    display:grid;
    //grid-template-rows:2.4rem auto 8rem 1fr;
    //grid-template-rows:2.4rem auto 1fr;
    grid-template-rows:auto 8rem 1fr;
    height:100vh;
    max-height:100vh;
    
    >.main{
	>div.box{
	    >.title{
		background:#000;
		color:#aaa;
		font-size:1rem;
		line-height:2.4rem;
		text-indent:0.6rem;
		position:relative;
	    
		>span{
		    position:absolute;
		    right:0.4rem;
		    font-size:1rem;
		}
	    }
	    >.video{
		>video{
		    width:100%;
		}
	    }
	}
    }
    >div:last-child{
	overflow:auto;
    }
}

/*
.container>div.title{
    background:#000;
    color:#aaa;
    font-size:1rem;
    line-height:2.4rem;
    text-indent:0.6rem;
    position:relative;

    >span{
	position:absolute;
	right:0.4rem;
	font-size:1rem;
    }
}
.container>div.video{
    text-align:center;
}
.container>div>video{
    max-width:100%;
    border-top:0.1rem solid #333;
    border-bottom:0.1rem solid #333;
}
.container>div:last-child{
    overflow:auto;
}

*/
