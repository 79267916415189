.container{
    position:fixed;
    //width:calc(100% - 2rem);
    //height:calc(100% - 2rem);
    //margin:1rem;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:#fff;
    z-index:10;
    overflow:auto;
    padding-bottom:2rem;
    background:#eee;

    >h1{
	margin-top:1rem;
	margin-bottom:1rem;
    }

    .jacket{
	text-align:center;
	list-style:none;

	>li{
	    display:inline-block;
	    vertical-align:top;
	    text-align:center;
	    margin-right:1rem;
	    margin-bottom:1rem;
	    background:#fff;
	    padding:1rem;
	    box-shadow:0 0 0.2rem #000;
	    cursor:pointer;

	    &:hover{
		opacity:0.6;
		box-shadow:0 0 0.2rem red;
	    }

	    >div{
		font-size:0.8rem;
		line-height:1.2rem;
		>span{
		    color:#fff;
		    padding:0.2rem;
		}
	    }
	}
    }
    
}
.container img{
    height:10rem;
    cursor:pointer;
    margin:0.4rem;
}
.container>h1{
    text-align:center;
    font-size:2rem;
    color:#333;
}
.container>div{
    text-align:center;
}
.container>div>input[type=button]{
    width:20rem;
    height:3rem;
    line-height:3rem;
    font-size:1.6rem;
    margin-top:1rem;
    background:#fff;
    cursor:pointer;
}
.container>div>input[type=button]:hover{
    opacity:0.7;
}

.info{
    position:fixed;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.3);
    z-index:10;
}
.info>div{
    background:#fff;
    box-shadow:0 0 4rem #000;
    position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    padding:3rem;
    width:40rem;
}
.info>div>p{
    margin-top:1.6rem;
}
.info>div>div{
    font-size:1.2rem;
    text-align:center;
}
.info>div>div>img{
    max-height:20rem;
}
.info>div>div>span{
    font-size:1.8rem;
    margin-top:1rem;
    display:block;
}
.info>div input[type=button]{
    width:100%;
    font-size:1.5rem;
    cursor:pointer;
    border-radius:0.4rem;
    border:0.1rem solid #aaa;
    padding:0.3rem;
}
.info>div input[type=button]:hover{
    opacity:0.8;
}
.info>div input[name=select]{
    background:#00c;
    color:#fff;
}
