.container{
    padding:1rem;
    color:#fff;
    font-size:1.2rem;
    //background:#000;

    display:grid;
    grid-template-columns:6rem 1fr;

    >div:first-child{
	max-height:6rem;
	max-width:6rem;
	text-align:center;
	vertical-align:middle;
	cursor:pointer;
	
	>img{
	    max-height:6rem;
	    max-width:6rem;
	    border:0.1rem solid #333;
	}

	&:hover{
	    opacity:0.8;
	    background:#333;
	}
    }
    >div:last-child{
	padding-left:1rem;
	font-size:0.9rem;
	white-space: pre-wrap;
    }

    p.release_date{
	color:#888;
	margin:0 0 0.3rem 0;

	>span{
	    color:#fff;
	    margin-right:0.4rem;
	    padding:0.3rem;
	}
    }
}
