*{
    margin:0;
    padding:0;
    box-sizing: content-box;
}
html{
    height:100vh;
    touch-action: manipulation;
}
body{
    overflow:hidden;
}

a{
    color:#03d6fe;
    text-decoration:none;
}
a:hover{
    color:#0ffff8;
    text-decoration:underline;
}
a:visited{
    color:#088bbc
}
